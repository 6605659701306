.wpo-section-title {
	text-align: center;
	margin-bottom: 60px;
	
	@include media-query(767px) {
		margin-bottom: 40px;
	}

	span{
		text-transform: uppercase;
		font-size: 20px;
		color: $theme-primary-color;
		font-family: $heading-font;
	}

	h2 {
		font-size: 50px;
		line-height: 1em;
		margin: 0;
		margin-top: 15px;
		position: relative;
		text-transform: capitalize;
		font-family: $heading-font-sub;

		@include media-query(767px) {
			font-size: 32px;
		}

		@include media-query(330px) {
			font-size: 30px;
		}

	}

}